<template>
  <div class="container mb-60 baojia-box liucheng-top rounded-lg">
    <v-row>
      <v-col   xl="8" lg="8" md="12" sm="12">
        <v-card flat class="pa-4">
            
            <div class="text-body-1 font-weight-bold">
                <router-link to="/fanli-Strategy"><span>{{$t('返利攻略')}} </span></router-link>
                
                <v-icon>mdi-chevron-double-left</v-icon>
                <span> {{ xianshitemai.name}}</span>
            </div>
          <v-row class="my-0">
                <template v-for="(n, k) in xianshitemai.article">
                  <v-col
                    :key="k"
                    cols="4"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                  >
                    <v-hover v-slot="{ hover }">
                        <v-card :flat="hover ? false : true" class="mx-auto pa-4 cursor-pointer"  @click="goDetail(n)">
                        <div>
                            <v-img contain width="246" height="184"  :src="n.face"></v-img>
                        </div>
                        <div class="text-body-1 font-weight-bold mt-1"> {{n.title}}</div>
                        <div class="mt-2 mb-2 line2 height-40" >{{n.content}} </div >
                        <v-divider></v-divider>
                        <v-card-actions> 
                        <span class="grey--text ">{{$t('通知')}}</span>
                        <v-spacer></v-spacer>
                        <div class="grey--text ">{{n.create_time | formFilter}}</div>
                        </v-card-actions>
                    </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
        </v-card>
      </v-col>
      <v-col  xl="4" lg="4" md="12" sm="12">
        <v-card flat class="pa-4">
            <div class="text-body-1 font-weight-bold">{{$t('文章搜索')}}</div>
            <v-card flat color="#F2F2F2" class="d-flex mt-2">
                <input type="text" class="pa-2" style="width:100%;outline: none;" v-model="keyword" :placeholder="$t('输入关键字进行搜索')">
                <v-btn
                    class=""
                    height="36"
                    width="60"
                    depressed
                    color="primary"
                    @click="toserach"
                  >
                    <div>
                <v-img
                  width="24"
                  height="24"
                  class=""
                  src="@/assets/images/icon/icon_search.svg"
                  alt=""
                ></v-img>

              </div>
                  </v-btn>
            </v-card>
        </v-card>
        <v-card flat class="pa-4">
            <div class="d-flex justify-space-between align-center">
                <div class="text-body-1 font-weight-bold">{{$t('最新发布')}}</div>
                <div>
                    <v-btn text width="20"  min-width="20">

                    <v-icon  @click="lefttoggle('left')">mdi-chevron-left</v-icon>
                    </v-btn>
                    {{this.pagearr.page}}/{{this.pagearr.pagetotal}}
                    <v-btn text width="20" min-width="20">

                    <v-icon  @click="lefttoggle('right')">mdi-chevron-right</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="d-flex align-center mt-4 cursor-pointer" v-for="(item ,index) in Articledata" :key="index" @click="goDetail(item)">
              <div>
                <v-img class="mr-2 rounded"  width="80" height="80" :src="item.face"></v-img>

              </div>
                <div>
                    <div class="mb-2 line2">{{item.title}}</div>
                    <div class="grey--text">{{item.create_time | formFilter}}</div>
                </div> 
            </div>
            <empty :describe="$t('暂无搜索内容~')" :imgSrc="emptysrc" v-if="Articledata.length==0"></empty>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import empty from "@/components/base/empty";
import ArticleCart from "@/components/base/articleCard";
export default {
  components: {
    ArticleCart,
    empty
  },
  data() {
    return {
        remaimodel:"",
        tools:"",
        keyword:'',
        param:{},
        index:0,
      xianshitemai: [],
      Articledata:[],
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 3,
        pagetotal: 50,
        total: 0,
      },
      emptysrc:require('@/assets/images/other/wusousuo@2x.png')
    };
  },
  mounted() {
    this.index=this.$route.query.index
    this.param["keyword"] = this.keyword;
    this.param["page"] = this.pagearr.page;
    this.param["page_size"] = this.pagearr.pagenum;
    this.getdata()
    this.getnewArticle()
  },
  methods: {
    //获取返利攻略
    getdata(){
      this.$api.home.fanliStrategy().then((res)=>{
        this.xianshitemai=res.data[this.index]
        console.log(this.xianshitemai , this.index)
      })
    }, 
    // 获取最新文章
    getnewArticle(){
      this.$api.home.latestRelease(this.param).then((res)=>{
        if(res.status=='success'){
          this.Articledata=res.data
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        }else {
          this.Articledata=[]
          this.pagearr.pagetotal = 0;
        }
      })
    },
    // 查看详情
    goDetail(val){
      this.$router.push({ path: '/strategyDetail' ,query:{id:val.id}})
    },
    lefttoggle(val){
      if(val=='left' && this.pagearr.page>1){
        this.pagearr.page--
        this.param['page']--
      }
      if(val=='right' && this.pagearr.page<this.pagearr.pagetotal){
        this.pagearr.page++
        this.param['page']++
      }
      
      this.getnewArticle()
    },
    toserach(){
      this.param["keyword"] = this.keyword;
      this.getnewArticle()
    }
  },
};
</script>
<style scoped>
.line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
a{
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.height-40{
  height: 40px;
}
</style>